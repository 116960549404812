import { createApp, h, defineAsyncComponent, reactive } from "vue";

let componentsLoaded = false;
const RECAPTCHA_SITE_KEY = "6Le2ggYfAAAAAMeaYt4WNH1TI3PG3t3pv5Qn3GV9";

async function loadComponents() {
    if (!componentsLoaded) {
        // siteSearch();
        searchFilters();
        quickSearchKeyword();
        companyLocationSearch();
        // articleAuthor();
        imageGallery();
        videoGallery();
        companyContact();
        jobManageForm();
        loadMoreButton();
        loadMoreButtonPopular();
        socialShare();
        articleGallery();
        addToCalendarLink();

        componentsLoaded = true;
    }
}

async function siteSearch() {
    const siteSearchEl = document.getElementById("site-search");
    if (siteSearchEl) {
        const SiteSearch = await defineAsyncComponent(() => import("./components/SiteSearch.js"));
        createApp(SiteSearch).mount("#site-search");
    }
}

async function searchFilters() {
    const searchFiltersEl = document.getElementById("search-filters");
    if (searchFiltersEl) {
        const SearchFilters = await defineAsyncComponent(() => import("./components/SearchFilters.vue"));
        createApp(SearchFilters).mount(searchFiltersEl);
    }
}

async function quickSearchKeyword() {
    const keywordEl = document.querySelector("input#keyword.quick-search-keyword");
    if (keywordEl) {
        const initQuickSearch = await import("./components/QuickSearchKeyword");
        initQuickSearch.default(keywordEl, window.qStore.state.directory, window.qStore.state.quickSearchUrl);
    }
}

async function articleAuthor() {
    const articleAuthorEl = document.querySelector("#author-select");
    if (articleAuthorEl) {
        const ArticleAuthor = await defineAsyncComponent(() =>
            import("./components/QuickSearchFields/ArticleAuthor.vue")
        );
        createApp(ArticleAuthor).mount(articleAuthorEl);
    }
}

async function imageGallery() {
    const imageGalleryEl = document.getElementById("image-carousel");
    if (imageGalleryEl) {
        const CompanyImageGallery = await defineAsyncComponent(() => import("./components/CompanyImageGallery.vue"));
        const CompanyImageGalleryApp = createApp({
            render: () => h(CompanyImageGallery),
        });
        CompanyImageGalleryApp.mount(imageGalleryEl);
    }
}

async function videoGallery() {
    const videoGalleryEl = document.getElementById("video-carousel");
    if (videoGalleryEl) {
        const CompanyVideoGallery = await defineAsyncComponent(() => import("./components/CompanyVideoGallery.vue"));
        const CompanyVideoGalleryApp = createApp({
            render: () => h(CompanyVideoGallery),
        });
        CompanyVideoGalleryApp.mount(videoGalleryEl);
    }
}

async function companyContact() {
    const companyContactEl = document.getElementById("company-contact-form");
    if (companyContactEl) {
        const CompanyContactForm = await defineAsyncComponent(() => import("./components/CompanyContactForm.vue"));
        const { VueReCaptcha } = await import("vue-recaptcha-v3");

        const CompanyContactFormApp = createApp({
            render: () => h(CompanyContactForm),
        }).use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY });

        CompanyContactFormApp.mount(companyContactEl);
    }
}

async function jobManageForm() {
    const jobManageEl = document.getElementById("job-manage-form");
    if (jobManageForm) {
        injectCss("vue-datepicker/main.css");
        const JobManageForm = await defineAsyncComponent(() => import("./components/JobManageForm.vue"));
        const { VueReCaptcha } = await import("vue-recaptcha-v3");
        const uploadcare = await import("uploadcare-widget/uploadcare.lang.en.min.js");
        const uploadcareTabEffects = await import(
            "uploadcare-widget-tab-effects/dist/uploadcare.tab-effects.lang.en.min.js"
        );
        window.uploadcare = uploadcare;
        window.uploadcareTabEffects = uploadcareTabEffects;
        const uploadCareLoadedEvent = new Event("uploadCareLoaded");
        document.querySelector("body").dispatchEvent(uploadCareLoadedEvent);

        const JobManageFormApp = createApp({
            render: () => h(JobManageForm),
        }).use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY });

        JobManageFormApp.mount(jobManageEl);
    }
}

async function companyLocationSearch() {
    const locationEl = document.querySelector("#companies-search #where");
    if (locationEl) {
        const DirectoryQuickSearchLocation = await defineAsyncComponent(() =>
            import("./components/QuickSearchFields/DirectoryLocation.vue")
        );
        createApp(DirectoryQuickSearchLocation).mount(locationEl);
    }
}

async function loadMoreButton() {
    const loadMoreButtonEl = document.getElementById("load-more");
    if (loadMoreButtonEl) {
        const LoadMoreButton = await defineAsyncComponent(() => import("./components/LoadMoreButton.vue"));
        const LoadMoreApp = createApp({
            render: () => h(LoadMoreButton),
        });
        LoadMoreApp.mount(loadMoreButtonEl);
    }
}

async function loadMoreButtonPopular() {
    const loadMoreButtonPopularEl = document.getElementById("load-more-popular");
    if (loadMoreButtonPopularEl) {
        const LoadMoreButtonPopular = await defineAsyncComponent(() =>
            import("./components/LoadMoreButtonPopular.vue")
        );
        const LoadMorePopularApp = createApp({
            render: () => h(LoadMoreButtonPopular),
        });
        LoadMorePopularApp.mount(loadMoreButtonPopularEl);
    }
}
async function socialShare() {
    const socialShareEl = document.getElementById("social-share");
    if (socialShareEl) {
        const SocialShare = await defineAsyncComponent(() => import("./components/SocialShare.vue"));
        const VueSocialSharing = await import("vue-social-sharing");
        const SocialShareApp = createApp({
            render: () => h(SocialShare),
        }).use(VueSocialSharing.default);

        SocialShareApp.mount(socialShareEl);
    }
}

async function articleGallery() {
    const articleGalleryEl = document.getElementById("article-gallery");
    if (articleGalleryEl) {
        await import("slick-carousel");
        const jQuery = await import("jquery");
        window.$ = jQuery.default;

        const loadedEvent = new Event("slick-loaded");
        document.body.dispatchEvent(loadedEvent);
    }
}

async function addToCalendarLink() {
    const addToCalendarEls = document.querySelectorAll(".calendar-add");
    if (addToCalendarEls.length) {
        const init = await import("./components/AddToCalendar");
        init.default(addToCalendarEls);
    }
}

function injectCss(pathToFile) {
    var linkEl = document.createElement("link");
    linkEl.href = location.origin + "/" + pathToFile;
    linkEl.rel = "stylesheet";
    linkEl.type = "text/css";
    document.getElementsByTagName("head")[0].appendChild(linkEl);
}

window.createApp = createApp;
window.reactive = reactive;

document.addEventListener("DOMContentLoaded", loadComponents);
document.addEventListener("htmx:afterSettle", function (evt) {
    if (evt.target.tagName === "BODY") {
        loadComponents();
    }
});
